<template>
  <div>
    <!-- 교육교재 -->
    <c-card title="LBL0002809" height="600px">
      <template slot="card-detail">
        <div class="col-12">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'education-material',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_CURRICULUM',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    }
  },
  watch: {
    'popupParam.eduCourseId'() {
      this.setTaskKey();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.setTaskKey();
    },
    setTaskKey() {
      let taskKey = this.popupParam.eduCourseId ? this.popupParam.eduCourseId : '';
      this.attachInfo.taskKey = taskKey
    },
  },
}
</script>
